.header {
  color: #fff;
  display: flex;
  justify-content: space-between;

  h2 {
    color: #fff;
    font-size: '18px'
  }

  &>div {
    span {
      margin-left: 10px;
    }
  }

  .logout {
    cursor: pointer;

    &:hover {
      color: #1890ff;
      transition: all .2s linear;
    }
  }
}

.main {
  min-width: 1000px;
  overflow-y: auto;
}