.detail {
  margin: 20px auto;
  width: 1060px;
  background-color: #fff;
}

.help_nav {
  width: 185px;
  padding: 15px 20px;
}

.help_peo {
  display: flex;
  border: 1px solid #f4f4f4;
  border-top: none;
}

.help_nav_item {
  height: 38px;
  width: 145px;
  line-height: 38px;
  text-align: center;
  display: block;
  border-bottom: 1px solid #f4f4f4;

  &.active {
    background-color: #f4f4f4;
  }
}

.help_detail {
  flex: 1;
  padding: 15px;
  border-left: 1px solid #f4f4f4;
}