$html-font-size: 75px;

@function strip-units($number) {
    @return $number / ($number * 0 + 1);
}

@mixin px2rem($property, $values...) {
    $max: length($values);
    $remValues: ();
    $html-font-size: strip-units($html-font-size);

    @for $i from 1 through $max {
        $value: nth($values, $i);

        @if not unitless($value) {
            $value: strip-units(nth($values, $i));
        }

        // value * 2 / 75
        $remValue: #{$value * 2 / $html-font-size}rem;

        @if $i <=$max {
            $remValues: append($remValues, join((), $remValue))
        }
    }

    #{$property}: join((), $remValues)
}